
import { Vue, Component } from 'vue-property-decorator'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import LocationImg from '@/assets/img/location.png'

type Event = {
  lnglat: {
    getLng: () => number;
    getLat: () => number;
  };
}

@Component
export default class Location extends Vue {
  loading = false
  // 默认定位南京市
  longitude = 118.78
  latitude = 32.07

  get assetId () {
    return this.$route.params.deviceId
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.assestsManage.selectAssetByAssetId, {
      assetId: this.assetId
    }).then(res => {
      if (res.longitude && res.latitude) {
        this.longitude = res.longitude
        this.latitude = res.latitude
      }
      this.$nextTick(() => {
        this.loadMap(this.longitude, this.latitude)
      })
    }).finally(() => {
      this.loading = false
    })
  }

  loadMap (lng?: number, lat?: number) {
    AMapLoader.load({
      key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(AMap => {
      const map = new AMap.Map('container', {
        center: [this.longitude, this.latitude],
        zoom: 15
      })
      const clickHandler = (lon: number, lat: number) => {
        const marker = new AMap.Marker({
          map: map,
          position: new AMap.LngLat(lon, lat),
          offset: new AMap.Pixel(-20, -40),
          icon: LocationImg,
          draggable: true
        })
        marker.on('dragend', (e: Event) => {
          this.longitude = e.lnglat.getLng()
          this.latitude = +e.lnglat.getLat()
        })
        map.add(marker)
      }
      if (lng && lat) {
        clickHandler(lng, lat)
      }
      map.on('click', (e: Event) => {
        map.clearMap()
        this.longitude = e.lnglat.getLng()
        this.latitude = e.lnglat.getLat()
        clickHandler(this.longitude, this.latitude)
      })
    }).catch(e => {
      this.$message({ type: 'error', message: '地图加载失败：' + JSON.stringify(e) })
    })
  }

  submit () {
    this.loading = true
    this.$axios.post(this.$apis.assestsManage.updateLocation, {
      assetId: this.assetId,
      longitude: this.longitude,
      latitude: this.latitude
    }).then(() => {
      this.$message({ message: '保存成功', type: 'success' })
    }).finally(() => {
      this.loading = false
    })
  }
}
